import React from "react";
import { Radio, RadioGroup, Box, Text, Stack } from "@chakra-ui/react";

const RadioboxInput = ({
  subtitle,
  value,
  onChange = () => {},

  options = [], // Supply with an Object { label, value, isDisabled }
  radioOrientation = "row",
  children
}) => {
  const hasChildren = Boolean(children);
  const hasSubtitle = Boolean(subtitle);

  const radioOptions = options.map(({ label, value: _value, ...props }) => (
    <Radio key={_value} value={_value} {...props}>
      {label}
    </Radio>
  ));

  return (
    <Box>
      <RadioGroup onChange={onChange} value={value}>
        {hasChildren ? (
          children
        ) : (
          <Stack direction={radioOrientation}>{radioOptions}</Stack>
        )}
      </RadioGroup>
      {hasSubtitle && (
        <Text variant="subtitle" mt=".5em" color="mandala.black.500">
          {subtitle}
        </Text>
      )}
    </Box>
  );
};

export default RadioboxInput;
